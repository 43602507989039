body
{
  background-color: pink;
}

h1
{
  margin-block-start: 0em;
  margin-block-end: 0em;
}

p
{
  margin: 0em;
  color: black;
}


.title
{
  padding-block: 0.75em;
  padding-left: 2em;
  color: black;
  background-color: orange;
}

.body
{  
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-top:  1em;
  padding-left: 2em;
}