nav
{
  padding-left: 2em;
  background-color: black;   
}

nav a
{
  color: white;
  text-decoration: none;
  padding-right: 1em;
}